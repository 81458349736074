import React from 'react';
import classNames from 'classnames';
import useCheckPC from '../../hooks/useCheckPC';
import Button from './Button';

const PageTitle = ({title, subtitle, setShowDescription}) => {
  const { isPC } = useCheckPC();
  return(
    <div className={classNames('page-title')}>
      <div className={classNames('page-title-wrap')}>
        {!!title && (
          <span className={classNames('title-small')}>
            {title}
          </span>
        )}
        {!!subtitle && (
          <span className={classNames('title-large')}>
            {subtitle}
          </span>
        )}
      </div>
      {!isPC && setShowDescription && (
        <Button
          styleType={'secondary'}
          classes={'fit-content button-small see-more'}
          onClick={() => setShowDescription(true)}
        >
          자세히 보기
        </Button>
      )}
    </div>
  )
}

export default PageTitle