import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Button = ({ styleType = 'primary', type = 'button', classes, children, ...props }) => {
  return (
    <button
      type={type}
      className={classNames(
        'button',
        styleType === 'primary' && 'primary',
        styleType === 'secondary' && 'secondary',
        styleType === 'warning' && 'warning',
        styleType === 'tertiary' && 'tertiary',
        styleType === 'grey-1' && 'grey-1',
        styleType === 'light-grey-2' && 'light-grey-2',
        styleType === 'grey-2' && 'grey-2',
        styleType === 'icon' && 'icon',
        styleType === 'text' && 'text',
        classes,
      )}
      {...props}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  styleType: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'icon', 'warning']),
  type: PropTypes.string,
  classes: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Button;
