export { ReactComponent as arrow_right } from './arrow_right.svg';
export { ReactComponent as arrow_right_grey } from './arrow_right_grey.svg';
export { ReactComponent as arrow_left } from './arrow_left.svg';
export { ReactComponent as arrow_below_fill } from './arrow_below_fill.svg';
export { ReactComponent as cursor_hand } from './cursor_hand.svg';
export { ReactComponent as logo } from './logo.svg';
export { ReactComponent as calendar } from './calendar.svg';
export { ReactComponent as attach } from './attach.svg';
export { ReactComponent as checkbox } from './checkbox.svg';
export { ReactComponent as checkbox_checked } from './checkbox_checked.svg';
export { ReactComponent as check } from './check.svg';
export { ReactComponent as close } from './close.svg';
export { ReactComponent as plus } from './plus.svg';
export { ReactComponent as menu } from './menu.svg';
export { ReactComponent as show_more } from './show_more.svg';
export { ReactComponent as hide_more } from './hide_more.svg';
export { ReactComponent as radio } from './radio.svg';
export { ReactComponent as radio_selected } from './radio_selected.svg';
export { ReactComponent as page_prev } from './page_prev.svg';
export { ReactComponent as page_next } from './page_next.svg';
export { ReactComponent as dot } from './dot.svg';
export { ReactComponent as kakao } from './kakao.svg';
export { ReactComponent as apple } from './apple.svg';
export { ReactComponent as pdf_download } from './pdf_download.svg';
export { ReactComponent as bul_exclamation } from './bul_exclamation.svg';
export { ReactComponent as checkbox2 } from './checkbox2.svg';
export { ReactComponent as checkbox2_checked } from './checkbox2_checked.svg';
export { ReactComponent as cross_icon } from './cross_icon.svg';




export const ICON_NAMES = [
  'arrow_right',
  'arrow_left',
  'arrow_below_fill',
  'cursor_hand',
  'calendar',
  'attach',
  'checkbox',
  'checkbox_checked',
  'check',
  'close',
  'arrow_right_grey',
  'plus',
  'menu',
  'show_more',
  'hide_more',
  'radio',
  'radio_selected',
  'page_next',
  'page_prev',
  'dot',
  'kakao',
  'apple',
  'pdf_download',
  'bul_exclamation',
  'cross_icon'
];