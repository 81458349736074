export const ESTIMATE_TYPE_MONTHLY_MOSILER = 1           // 견적서 타입 : 월간모시러
export const ESTIMATE_TYPE_DRIVER_DISPATCH = 2           // 견적서 타입 : 수행기사 파견

export const PAGE = {
    PAGE_01: 1,
    PAGE_02: 2,
    PAGE_03: 3,
    PAGE_04: 4,
    PAGE_05: 5,
    PAGE_06: 6,
    PAGE_07: 7,
    PAGE_08: 8,
    PAGE_09: 9,
    PAGE_10: 10,
    PAGE_11: 11,
    PAGE_12: 12,
    PAGE_13: 13,
    PAGE_14: 14,
    PAGE_15: 15,
    PAGE_16: 16,
    PAGE_17: 17,
    PAGE_18: 18,
    PAGE_19: 19,
    PAGE_20: 20,

}

export const KAKAO_CLIENT_ID = '9c8644cebd4d4307dd91d7678b7f66ca'